import { addIcon } from './MenuModule';
import {
    ControlPainelMenuDictionary,
    ControlPainelMenuDictionary2x,
} from '../../../../components/images';
import { EDITOR_ELEMENT_HEADER } from '../core/editor-element/EditorElementHeader';
import { elementCanBeInsertedAtSelection } from '../core/EditorElements';

/**
 * @param editor {EditorCustom}
 * @param params {MenuModuleParams}
 */
export function createStructureMenu(editor, params) {
    addIcon(
        editor,
        'customEditorDictionary',
        ControlPainelMenuDictionary,
        ControlPainelMenuDictionary2x,
    );

    editor.ui.registry.addMenuItem('customDocumentSettings', {
        // I18N
        text: 'Propriedades do documento',
        onAction: params.documentSettingsAction,
    });
    editor.ui.registry.addMenuItem('customHyphenationSettings', {
        // I18N
        text: 'Hifenização',
        onAction: params.hyphenationSettingsAction,
    });
    if (params.isAdmin) {
        editor.ui.registry.addMenuItem('customDictionary', {
            // I18N
            text: 'Dicionário',
            onAction: params.dictionaryAction,
            icon: 'customEditorDictionary',
        });
    }

    // Temporarily disable (#64202)
    // editor.ui.registry.addNestedMenuItem('customIdentification', {
    //     // I18N
    //     text: 'Identificação',
    //     getSubmenuItems: function () {
    //         return [
    //             {
    //                 type: 'menuitem',
    //                 // I18N
    //                 text: 'Adicionar e configurar',
    //                 onAction: () => {
    //                     if (elementCanBeInsertedAtSelection(editor, null)) {
    //                         params.identificationAction();
    //                     }
    //                 },
    //             },
    //             {
    //                 type: 'menuitem',
    //                 // I18N
    //                 text: 'Suprimir identificação em todo o documento',
    //                 onAction: () => {
    //                     if (elementCanBeInsertedAtSelection(editor, null)) {
    //                         const onAction = () => {
    //                             const { editorElements } =
    //                                 editor.custom.coreModule;
    //                             const editorElementHeader =
    //                                 editorElements.getEditorElementInstance(
    //                                     EDITOR_ELEMENT_HEADER,
    //                                 );
    //                             editorElementHeader.insertElementAtCursor(
    //                                 editor,
    //                                 {
    //                                     identification: {
    //                                         suppress: true,
    //                                         affectAllPages: true,
    //                                     },
    //                                 },
    //                             );
    //                         };
    //
    //                         params.identificationSuppressInAllPagesAction(
    //                             onAction,
    //                         );
    //                     }
    //                 },
    //             },
    //             {
    //                 type: 'menuitem',
    //                 // I18N
    //                 text: 'Suprimir identificação nesta página',
    //                 onAction: () => {
    //                     if (elementCanBeInsertedAtSelection(editor, null)) {
    //                         const { editorElements } = editor.custom.coreModule;
    //                         editorElements.insertElementAtCursor(
    //                             EDITOR_ELEMENT_HEADER,
    //                             {
    //                                 identification: {
    //                                     suppress: true,
    //                                 },
    //                             },
    //                         );
    //                     }
    //                 },
    //             },
    //             {
    //                 type: 'menuitem',
    //                 // I18N
    //                 text: 'Expor identificação em todo o documento',
    //                 onAction: () => {
    //                     if (elementCanBeInsertedAtSelection(editor, null)) {
    //                         const onAction = () => {
    //                             const { editorElements } =
    //                                 editor.custom.coreModule;
    //                             const editorElementHeader =
    //                                 editorElements.getEditorElementInstance(
    //                                     EDITOR_ELEMENT_HEADER,
    //                                 );
    //                             editorElementHeader.insertElementAtCursor(
    //                                 editor,
    //                                 {
    //                                     identification: {
    //                                         suppress: false,
    //                                         affectAllPages: true,
    //                                     },
    //                                 },
    //                             );
    //                         };
    //                         params.identificationExposeInAllPagesAction(
    //                             onAction,
    //                         );
    //                     }
    //                 },
    //             },
    //         ];
    //     },
    // });

    // Temporarily disable (#64202)
    // editor.ui.registry.addMenuItem('customPagination', {
    //     // I18N
    //     text: 'Paginação',
    //     onAction: params.paginationAction,
    // });
}
